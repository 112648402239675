<template>
  <div>
    <b-img :src="bgHeader" class="header mb-2" />
    <b-row class="m-0">
      <b-col lg="4" class="order-2 order-lg-1 my-0 p-0">
        <b-card>
          <b-card-body class="py-1 px-3 px-lg-2 d-flex flex-column justify-content-center">
            <div v-if="bill" class="d-flex flex-column align-items-center justify-content-center">
              <b-icon-check-circle variant="primary" font-scale="4" class="mb-2 text-center" />
              <h3 class="text-center mb-1">
                {{ $t('onboarding.success.success') }}
              </h3>
              <p class="text-center mb-2 text-muted">
                {{ $t('onboarding.success.email-success') }}
              </p>
            </div>
            <div v-else class="d-flex flex-column align-items-center justify-content-center">
              <b-icon-check-circle variant="primary" font-scale="4" class="mb-2 text-center" />
              <h3 class="text-center mb-1">
                {{ $t('onboarding.success.success-nobill') }}
              </h3>
              <p class="text-center mb-2 text-muted">
                {{ $t('onboarding.success.email-success-nobill') }}
              </p>
            </div>
            <!-- Detalles usuario -->
            <b-row v-if="bill">
              <b-col cols="12" sm="6" class="my-50">
                <h6>{{ $t('onboarding.success.custom-details') }}</h6>
                <div class="d-flex flex-column">
                  <small>{{ $t('onboarding.success.name') }}</small>
                  <small class="text-muted mb-50">{{ loggedUser.name }} {{ loggedUser.surname }} </small>
                  <small>{{ $t('onboarding.success.email') }}</small>
                  <small class="text-muted mb-50">{{ loggedUser.email }}</small>
                </div>
              </b-col>

              <!-- Ref de compra -->
              <b-col v-if="item" cols="12" sm="6">
                <h6>
                  {{ $t('onboarding.success.order-details') }}
                </h6>
                <div class="d-flex flex-column">
                  <small>{{ $t('onboarding.success.ref') }}</small>
                  <small class="text-muted mb-50">{{ item.ref }}</small>
                  <small>{{ $t('onboarding.success.product') }}</small>
                  <small class="text-muted mb-50">{{ translate(item.name) }} </small>
                  <small>{{ $t('onboarding.success.total') }} </small>
                  <small
                    v-if="item.currencyPriceTotal === '0' || item.total === 0"
                    class="text-muted mb-50"
                  >{{ $t('onboarding.checkout.free') }}
                  </small>
                  <small
                    v-else
                    class="text-muted mb-50"
                  >{{ item.total || item.currencyPriceTotal }} {{ item.currencySymbol }} {{ $t('onboarding.success.iva') }}
                  </small>
                </div>
              </b-col>
            </b-row>
            <!-- Detalles usuario -->
            <b-row v-else>
              <b-col
                cols="12"
                sm="6"
                offset-sm="3"
                class="my-50"
              >
                <h6>{{ $t('onboarding.success.custom-details') }}</h6>
                <div class="d-flex flex-column">
                  <small>{{ $t('onboarding.success.name') }}</small>
                  <small class="text-muted mb-50">{{ loggedUser.name }} {{ loggedUser.surname }} </small>
                  <small>{{ $t('onboarding.success.email') }}</small>
                  <small class="text-muted mb-50">{{ loggedUser.email }}</small>
                  <small>{{ $t('onboarding.success.username') }}</small>
                  <small class="text-muted mb-50">{{ loggedUser.username }}</small>
                </div>
              </b-col>
            </b-row>

            <!-- Descargar factura -->
            <!--    <div class="d-flex align-items-center justify-content-center">
              <b-button variant="outline-primary">
                Descargar factura
              </b-button>
            </div> -->
          </b-card-body>
        </b-card>
      </b-col>
      <b-col lg="8" class="d-flex flex-column align-items-center justify-content-center order-1 order-lg-2">
        <h1 class="mt-1 mb-2 text-center">
          {{ $t('onboarding.success.welcome') }}
          <span class="text-primary">Nectios</span>
        </h1>
        <b-button variant="primary" class="ml-50 mb-2 mb-md-0" @click="ResumenUrl">
          {{ $t('onboarding.success.explore-button') }}
        </b-button>
        <!--     <span class="mt-2 text-muted">
          {{ $t('onboarding.success.redirect') }}
        </span> -->
        <div class="community-img">
          <b-img alt="join community image" :src="exploreCommunity" />
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import UserAvatar from '@core/components/user/UserAvatar.vue';
import { getImageResource } from '@/@core/utils/image-utils';
import CustomfieldsModal from '@/@core/components/modal/CustomfieldsModal.vue';
import ClassifiersModal from '@/@core/components/modal/ClassifiersModal.vue';
import { translateTranslationTable } from '@/@core/libs/i18n/utils';
import Placeholder from '@/assets/images/placeholders/light/placeholder-dark.jpeg';
import Vue from 'vue';
import ExploreCommunity from '@/assets/images/pages/community-explore.svg';
import BgHeader from '@/assets/images/placeholders/light/banner-nectios.png';

export default {
  name: 'MySuccess',
  components: {
    UserAvatar,
    ClassifiersModal,
    CustomfieldsModal,
    ExploreCommunity,
  },
  data() {
    return {
      isLoading: false,
      newComment: '',
      nextStep: false,
      disabled: false,
      disabledButtons: true,
      selectedAnswer: {},
      imageSrc: null,
      imageForum: null,
      forum: { name: '', description: '' },
    };
  },
  computed: {
    id() {
      return this.$route.params.id;
    },
    bgHeader() {
      return BgHeader;
    },
    bill() {
      if (this.id === 'thankyou') {
        return false;
      }
      return true;
    },
    api() {
      return process.env.VUE_APP_API_PAYMENT;
    },
    token() {
      return Vue.$cookies.get('accessToken');
    },
    type() {
      return this.$route.params.type;
    },
    loggedUser() {
      return this.$store.getters.loggedUser;
    },
    item() {
      let result = {};
      if (this.$store.getters.subscriptions.data) {
        result = { ...result, ...this.$store.getters.subscriptions.data[0] };
      }
      if (this.$store.getters.subscriptionPlans?.unpaginated) {
        result = { ...result, ...this.$store.getters.subscriptionPlans.unpaginated[0] };
      }
      return result;
    },
    myData() {
      return this.$store.getters.purchase.unpaginated[this.$store.getters.purchase.unpaginated.length - 1];
    },
    Placeholder() {
      return Placeholder;
    },
    currentLocale() {
      return this.$store.state.locale.currentLocale;
    },
    collective() {
      return this.$store.state.collective.collective;
    },
    communityName() {
      return this.collective.name;
    },
    communitySlug() {
      return this.$route.params.community;
    },
    exploreCommunity() {
      return ExploreCommunity;
    },
  },

  // Fetch data when component is created!
  async created() {
    this.isLoading = true;
    if (this.bill) this.fetchData();
    this.isLoading = false;
    /*    setTimeout(async () => {
      await this.ResumenUrl();
    }, 3000); */
  },
  methods: {
    async ResumenUrl() {
      window.location = `${process.env.VUE_APP_COMMUNITY_TEMPLATE_URL.replace('{slug}', this.communitySlug).replace(
        '{subdomain}',
        'app',
      )}/backoffice`;
    },
    async fetchData() {
      this.isLoading = true;
      await this.$store.dispatch('getItemsPayments', {
        itemType: 'subscriptions',
        requestConfig: {
          subscriptionKey: this.id,
        },
      });
      await this.$store.dispatch('getItemsPayments', {
        itemType: 'subscriptionPlans',
        requestConfig: {
          subscriptionKey: this.id,
        },
      });
      this.isLoading = false;
    },
    translate(field) {
      return translateTranslationTable(this.currentLocale, field);
    },
  },
};
</script>
<style lang="scss" scoped>
.community-img {
  display: none;
}
.header {
  object-fit: cover;
  background-color: rgb(255, 255, 255);
  width: 100%;
  border-radius: 1.2rem;
}

@media (min-width: 767px) {
  .container {
    height: 55vh;
    max-height: 55vh;
  }
  .community-img {
    display: flex;
    max-width: 100%;
    img {
      width: 100%;
    }
  }
}
</style>
